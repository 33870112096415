import Head from "next/head";

import { PageNotFoundScreen } from "@chapter/nightingale-base";

const PageNotFound = () => {
    return (
        <>
            <Head>
                <title>404 &middot; Page Not Found</title>
            </Head>
            <PageNotFoundScreen />
        </>
    );
};

export default PageNotFound;
